import Loading from "components/Loading";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import Permission from "components/Permission";
import { MODULE_GREETING_CARD } from "shared/constants";

const GreetingCard = React.lazy(
  () => import("containers/ServiceManagement/GreetingCard")
);

function GreetingCardRoute() {
  return (
    <Route
      path="greeting-card"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<GreetingCard />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_GREETING_CARD]}
          />
        </Suspense>
      }
    />
  );
}

export default GreetingCardRoute;
