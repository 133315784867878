import { Route } from "react-router-dom";
import BuildingRoute from "./buildingRoute";
import VersionRoute from "./versionRoute";
import IconRoute from "./iconRoute";
import NotificationRoute from "./notificationRoute";
import WhiteLabelRoute from "./whiteLabelRoute";
import CityRoute from "./cityRoute";
import DistrictRoute from "./district";
import WardRoute from "./ward";
import BannerRoute from "./bannerRoute";
import ServicePartnerRoute from "./servicePartner";
import AdsPartnerRoute from "./adsPartnerRoute";
import PartnerOrderRoute from "./partnerOrderRoute";
import PartnerOrderMerchantRoute from "./PartnerOrderMerchantRoute";
import BillLockRoute from "./billLockRoute";
import ConfigModuleForBuildingRoute from "./configModuleForBuilding";
import ModuleConfigRoute from "./moduleConfigRoute";
import RemoteConfigRoute from "./remoteConfigRoute";
import CategoryHotDealRoute from "./categoryHotDeal";
import HotDealRoute from "./hotDeal";
import GreetingCardRoute from "./greetingCardRoute";

function ManagementRoutes() {
  return (
    <Route path="management">
      {BuildingRoute()}
      {IconRoute()}
      {VersionRoute()}
      {WhiteLabelRoute()}
      {NotificationRoute()}
      {CityRoute()}
      {DistrictRoute()}
      {WardRoute()}
      {BannerRoute()}
      {RemoteConfigRoute()}
      {ServicePartnerRoute()}
      {CategoryHotDealRoute()}
      {HotDealRoute()}
      {AdsPartnerRoute()}
      {PartnerOrderRoute()}
      {PartnerOrderMerchantRoute()}
      {BillLockRoute()}
      {PartnerOrderRoute()}
      {ConfigModuleForBuildingRoute()}
      {ModuleConfigRoute()}
      {GreetingCardRoute()}
    </Route>
  );
}

export default ManagementRoutes;
