import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_PARTNER_ORDER } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const PartnerOrderMerchant = React.lazy(
  () => import("containers/ServiceManagement/PartnerOrderMerchant")
);

function PartnerOrderMerchantRoute() {
  return (
    <Route
      path="partner-order-merchant"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<PartnerOrderMerchant />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_PARTNER_ORDER]}
          />
        </Suspense>
      }
    />
  );
}

export default PartnerOrderMerchantRoute;
