const fontSizeMedium = 14;
const textColor = "rgba(0, 0, 0, 0.65)";

const theme = {
  // color
  color: {
    primary: "#16916A",
    link: "#0530AD",
    success: "#87d068",
    warning: "#f50",
    error: "#f63b4b",
    default: "#fff",
  },
  // text
  textColor: "rgba(0, 0, 0, 0.70)",
  textSecondaryColor: "rgba(0, 0, 0, 0.45)",
  textColorInverse: "#ffffff",
  disabledColor: "rgba(0, 0, 0, 0.20)",
  fontWeight: {
    normal: 400,
    semiBold: 500,
    bold: 600,
  },
  fontSize: {
    xs: 8,
    sm: 12,
    md: 14,
    lg: 16,
  },
  heading: {
    one: Math.ceil(fontSizeMedium * 2.71),
    two: Math.ceil(fontSizeMedium * 2.14),
    three: Math.ceil(fontSizeMedium * 1.71),
    four: Math.ceil(fontSizeMedium * 1.42),
    five: Math.ceil(fontSizeMedium * 1.14),
  },
  lintHeight: 1.5715,
  // border
  borderColor: "#ddd",
  borderRadius: 6,
  padding: {
    xss: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
  },
  margin: {
    xss: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
  },
  // sidebar
  backgroundSidebar: "#16916A",
  backgroundSidebarSecondary: "#16916A",
  backgroundItemActiveSidebar: "#44BEAA",
  // navbar
  colorNavbar: textColor,
  backgroundNavbar: "#f5f5f5",
  // content
  backgroundContent: "#f0f2f5",
  labelColor: "#7F8D9E",
  primaryColor: "#16916A",
  iconSize: 20,
  offsetScrollX: -10,
};

export default theme;
