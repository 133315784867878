import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const NOTIFICATION_DEFAULT_FILTER = {
  userName: undefined,
  notificationType: undefined,
  unitGroupName: undefined,
  title: undefined,
};

export const ADS_NOTICE = "ADS_NOTICE";
export const WEBVIEW_NOTICE = "WEBVIEW_NOTICE";
export const NORMAL_NOTICE = "NORMAL_NOTICE";

export const ELEVATOR_RESERVATION = "ELEVATOR_RESERVATION";
export const EZIN_INSURANCE = "EZIN_INSURANCE";
export const FEEDBACK = "FEEDBACK";
export const HOTLINE = "HOTLINE";
export const PROPERTY_POST = "PROPERTY_POST";
export const TRIPI_FLIGHT = "TRIPI_FLIGHT";
export const TRIPI_HOTEL = "TRIPI_HOTEL";
export const TRIPI_TOPUP = "TRIPI_TOPUP";
export const VISITOR = "VISITOR";
export const REE = "REE";

export const NOTIFICATION_TYPES = [
  { id: NORMAL_NOTICE, name: language.normal },
  { id: WEBVIEW_NOTICE, name: language.webView },
  { id: ADS_NOTICE, name: language.advertising },

  // { id: ELEVATOR_RESERVATION, name: language.elevator_reservation },
  // { id: EZIN_INSURANCE, name: language.ezin_insurance },
  // { id: FEEDBACK, name: language.feedback },
  // { id: HOTLINE, name: language.hotline },
  // { id: PROPERTY_POST, name: language.property_post },
  // { id: TRIPI_FLIGHT, name: language.tripi_flight },
  // { id: TRIPI_HOTEL, name: language.tripi_hotel },
  // { id: TRIPI_TOPUP, name: language.tripi_topup },
  // { id: VISITOR, name: language.visitor },
  // { id: REE, name: language.ree },
];

export const NOTIFICATION_TYPE_BY_ID = convertArrToObj(
  NOTIFICATION_TYPES,
  "id"
);
